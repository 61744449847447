<template>
  <div id="supportDepartments">
    <b-card no-body>
      <b-card-header class="d-flex align-items-center">
        <div><h4 class="mb-0">Support Departments</h4></div>
        <div class="ml-auto">
          <b-link to="/tickets/departments/add" class="btn btn-success">Add New Department</b-link>
        </div>
      </b-card-header>
      <b-table
        ref="depTableRef"
        :items="fetchDepartments"
        :fields="depTableFields"
        show-empty
        :empty-html="
          isLoadingDep
            ? `<span class='fa fa-spin fa-spinner'></span> Loading Departments`
            : 'Departments not found'
        "
      >
        <template #cell(status)="data">
          <b-badge :variant="data.item.status ? 'success' : 'danger'">{{
            data.item.status ? "Active" : "Disabled"
          }}</b-badge>
        </template>
        <template #cell(actions)="data">
          <b-link :to="`/tickets/departments/edit/${data.item._id}`" class="btn btn-sm btn-warning"
            >Edit</b-link
          >
        </template>
      </b-table>
    </b-card>
  </div>
</template>
<script>
import { BCard, BTable, BCardHeader, BCardBody, BButton, BLink, BBadge } from "bootstrap-vue";
import useTickets from "../useTickets";
export default {
  name: "supportDepartments",
  components: {
    BCard,
    BTable,
    BCardBody,
    BCardHeader,
    BButton,
    BLink,
    BBadge,
  },

  methods: {
    refetchData() {
      this.depTableRef.refresh();
    },
  },
  setup(data, { emit }) {
    const { depTableFields, depTableRef, fetchDepartments, isLoadingDep } = useTickets();

    return {
      depTableFields,
      depTableRef,
      fetchDepartments,
      isLoadingDep,
    };
  },
};
</script>
